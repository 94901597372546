import { defaultDataIdFromObject } from '@apollo/client'

/**
 * By default Apollo client looks for __typename and id. Because Contentful uses
 * sys.id, override this behaviour
 */
export const dataIdFromObject = (responseObject: any) => {
  if (responseObject.__typename === 'Sys')
    return `${responseObject.__typename}:${responseObject.id}`
  if (responseObject.sys?.id)
    return `${responseObject.__typename}:${responseObject.sys.id}`
  return defaultDataIdFromObject(responseObject)
}
