import { ApolloProvider } from '@apollo/client'
import { graphqlClient } from './graphql-client'

export interface GraphqlClientProviderProps {
  children: React.ReactNode
}

export const GraphqlClientProvider = (props: GraphqlClientProviderProps) => {
  const client = graphqlClient()

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}

export const wrapRootElement = ({ element }: any) => (
  <GraphqlClientProvider>{element}</GraphqlClientProvider>
)
