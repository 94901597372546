import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'
import { dataIdFromObject } from './data-id-from-object'

export const graphqlClient = () => {
  const httpLink = createHttpLink({
    fetch,
    uri: process.env.GATSBY_CONTENTFUL_GRAPHQL_ENDPOINT,
  })
  const authLink = setContext((_, { headers }) => {
    // Initialize a client with the delivery token when building the site on
    // Netlify. Otherwise, use the preview token.
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${
          process.env.NETLIFY
            ? process.env.GATSBY_CONTENTFUL_DELIVERY_TOKEN
            : process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN
        }`,
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({ dataIdFromObject }),
  })

  return client
}
